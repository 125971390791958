import { ChakraProvider, Box, Grid, theme } from "@chakra-ui/react";
import { RecoverForm } from "./components/RecoverForm/RecoverForm";

export const App = () => (
    <ChakraProvider theme={theme}>
        <Box textAlign='center' fontSize='xl'>
            <Grid minH='100vh'>
                <RecoverForm />
            </Grid>
        </Box>
    </ChakraProvider>
);
