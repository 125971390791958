import axios, { AxiosInstance } from "axios";
import { environment } from "../config/environment";

const getAxiosClient = (): AxiosInstance => {
    const axiosInstance = axios.create({
        baseURL: environment.apiUrl,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return axiosInstance;
};

export const axiosClient = getAxiosClient();
