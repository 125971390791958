import { useState } from "react";
import {
    Flex,
    Img,
    Input,
    FormErrorMessage,
    Text,
    Button,
    InputGroup,
    InputRightElement,
    FormControl,
    FormLabel,
    Alert,
    AlertTitle,
    AlertDescription,
    AlertIcon,
} from "@chakra-ui/react";
import Logo from "./logo.png";
import { FaEye, FaEyeSlash, FaRegCheckCircle } from "react-icons/fa";
import { SubmitHandler, useForm } from "react-hook-form";
import { axiosClient } from "../../endpoint/axiosClient";

interface FormValues {
    password: string;
    confirmPassword: string;
}

type FormStatus =
    | {
          type: "fillingForm";
      }
    | {
          type: "success";
      }
    | {
          type: "error";
          message: string;
      };

const RecoverForm = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

    const [status, setStatus] = useState<FormStatus>({
        type: "fillingForm",
    });

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            password: "",
            confirmPassword: "",
        },
    });
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        if (data.password !== data.confirmPassword) {
            setError("confirmPassword", {
                type: "custom",
                message: "Passwords do not match",
            });
            return;
        }

        try {
            const response = await axiosClient.post("/auth/resetPassword", {
                token: token,
                password: data.password,
                confirmPassword: data.confirmPassword,
            });

            if (!response.data.success) {
                setStatus({
                    type: "error",
                    message: response.data.message ?? "",
                });
                return;
            }
            setStatus({
                type: "success",
            });
        } catch (error: any) {
            const { message = "" } = error;
            setStatus({
                type: "error",
                message: message,
            });
        }
    };

    const token = urlParams.get("token");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
                backgroundColor={"#a1c2d7"}
                alignItems={"center"}
                height={"100vh"}
                direction={"column"}
                paddingTop={"50px"}
                padding={"50px"}
            >
                <Img
                    width={"50%"}
                    maxWidth={"200px"}
                    src={Logo}
                    marginRight={"-10px"}
                />
                {status.type === "success" && (
                    <Flex
                        direction='column'
                        alignItems={"center"}
                        marginTop={"20px"}
                    >
                        <FaRegCheckCircle size={50} color='#2F855A' />
                        <Text
                            fontSize={"2xl"}
                            as={"h1"}
                            fontWeight={"bold"}
                            color='#2F855A'
                        >
                            Password updated
                        </Text>
                    </Flex>
                )}
                {status.type !== "success" && (
                    <>
                        <Text
                            fontSize={"2xl"}
                            as={"h1"}
                            marginTop={"20px"}
                            marginBottom={"10px"}
                        >
                            Recover Password
                        </Text>
                        <FormControl
                            isInvalid={!!errors.password}
                            maxWidth={"400px"}
                            marginBottom='10px'
                        >
                            <FormLabel>Password</FormLabel>
                            <InputGroup>
                                <Input
                                    placeholder='New Password'
                                    backgroundColor={"white"}
                                    type={isShowPassword ? "text" : "password"}
                                    {...register("password", {
                                        required: {
                                            value: true,
                                            message: "Password is required",
                                        },
                                        minLength: {
                                            value: 8,
                                            message:
                                                "Password must be at least 8 characters",
                                        },
                                    })}
                                />
                                <InputRightElement>
                                    {isShowPassword ? (
                                        <FaEye
                                            cursor={"pointer"}
                                            onClick={() =>
                                                setIsShowPassword(false)
                                            }
                                        />
                                    ) : (
                                        <FaEyeSlash
                                            cursor={"pointer"}
                                            onClick={() =>
                                                setIsShowPassword(true)
                                            }
                                        />
                                    )}
                                </InputRightElement>
                            </InputGroup>
                            {errors.password?.message && (
                                <FormErrorMessage>
                                    {errors.password.message}
                                </FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl
                            isInvalid={!!errors.confirmPassword}
                            maxWidth={"400px"}
                            marginBottom='20px'
                        >
                            <FormLabel>Confirm Password</FormLabel>
                            <InputGroup>
                                <Input
                                    placeholder='Confirm New Password'
                                    backgroundColor={"white"}
                                    type={
                                        isShowConfirmPassword
                                            ? "text"
                                            : "password"
                                    }
                                    {...register("confirmPassword", {
                                        required: {
                                            value: true,
                                            message:
                                                "Confirm password is required",
                                        },
                                        minLength: {
                                            value: 8,
                                            message:
                                                "Password must be at least 8 characters",
                                        },
                                    })}
                                />
                                <InputRightElement>
                                    {isShowConfirmPassword ? (
                                        <FaEye
                                            cursor={"pointer"}
                                            onClick={() =>
                                                setIsShowConfirmPassword(false)
                                            }
                                        />
                                    ) : (
                                        <FaEyeSlash
                                            cursor={"pointer"}
                                            onClick={() =>
                                                setIsShowConfirmPassword(true)
                                            }
                                        />
                                    )}
                                </InputRightElement>
                            </InputGroup>
                            {errors.confirmPassword?.message && (
                                <FormErrorMessage>
                                    {errors.confirmPassword.message}
                                </FormErrorMessage>
                            )}
                        </FormControl>
                    </>
                )}

                {status.type === "error" && (
                    <Alert
                        status='error'
                        size={"md"}
                        maxWidth={"600px"}
                        marginBottom='20px'
                        flexWrap={"wrap"}
                        alignItems={"center"}
                    >
                        <AlertIcon />
                        <AlertTitle fontSize='md'>
                            Password could not be changed
                        </AlertTitle>
                        <AlertDescription fontSize='md'>
                            {status.message}
                        </AlertDescription>
                    </Alert>
                )}

                {status.type !== "success" && (
                    <Button
                        type='submit'
                        backgroundColor={"#446d87"}
                        color='white'
                    >
                        Save
                    </Button>
                )}
            </Flex>
        </form>
    );
};

export { RecoverForm };
